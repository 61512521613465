<template>
  <div class="cardResumen">
    <HeaderAeronaveVuelosEscogidos
      v-if="mostrarHeader"
      class="cardResumen__header"
    />
    <TicketInfoVuelo :mostrarDetalles="mostrarDetalles" class="pb-4" />
    <div class="cardResumen__pie">
      <div v-if="editarPasajeros" class="cardResumen__pasajeros pb-4">
        <CambiarPasajeros />
      </div>

      <!-- <div
        v-if="mostrarEquipaje"
        class="cardResumen__equipaje d-flex align-center pb-4"
      >
        <img :src="icons.purple.luggage" alt="" height="25" class="pr-5" />
        <h3>Equipaje de mano y de cabina</h3>
      </div> -->

      <div
        v-if="mostrarPrecio"
        class="cardResumen__precio d-flex flex-column text-center pb-4"
      >
        <div class=" align-center justify-center">
          <h2 class="pr-3" :class="{ 'green--text': promoAplicada }">
           Total:  ${{ precioEnMiles(precioEnDolares) }} USD  
          </h2>
          <h4 class="pr-3" style="color: black" >
            ${{ precioEnMiles(precioEnCLPBasadoEnDolarDeHoy) }} CLP
          </h4>
        </div>
        <!-- <p v-show="!elVueloEsPrivado">
          <span v-if="preciosPorPersonaConFormato.vuelta">Ida: </span>

          <span :class="{ 'green--text': promoAplicada }">
            ${{
              moneda == "clp"
                ? precioEnMiles(precioEnCLPBasadoEnDolarDeHoyPorPersona)
                : precioEnMiles(precioEnDolaresPorPersona)
            }}
            {{ moneda == "clp" ? "CLP" : "USD" }}
          </span>
          {{ lenguages[idioma].cardReservarVuelo.precioPorPasajero }}
        </p> -->
        <p v-if="preciosPorPersonaConFormato.vuelta">
          Vuelta: ${{ preciosPorPersonaConFormato.vuelta }} precio por pasajero
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CambiarPasajeros from "@/components/CambiarPasajeros";
import TicketInfoVuelo from "@/components/Cards/Subcomponentes/TicketInfoVuelo";
import HeaderAeronaveVuelosEscogidos from "@/components/Cards/Subcomponentes/HeaderAeronaveVuelosEscogidos";
import { mapGetters, mapState } from "vuex";
export default {
  name: "CardResumenVuelo",
  components: {
    TicketInfoVuelo,
    CambiarPasajeros,
    HeaderAeronaveVuelosEscogidos,
  },
  props: {
    editarPasajeros: {
      default: true,
    },
    mostrarHeader: {
      default: true,
    },
    mostrarDetalles: {
      default: true,
    },
    mostrarPrecio: {
      default: true,
    },
    mostrarEquipaje: {
      default: true,
    },
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState(["icons"]),
    ...mapState("Ventas", ["vuelosElegidos", "promoAplicada"]),
    ...mapGetters("Ventas", ["montoTotal"]),
    ...mapState("Experiencias", ["moneda", "monedas"]),

    elVueloEsPrivado() {
      const {
        vuelosElegidos: {
          ida: {
            data: { privado },
          },
        },
      } = this;
      return privado;
    },
    precioEnDolares() {
      const {
        monedas: { dolar },
        vuelosElegidos: {
          ida: {
            data: { precio, precioEnDolares },
          },
          info: { pasajeros },
        },
      } = this;
      const { montoTotal } = this;
      if (precioEnDolares) return precioEnDolares * pasajeros;
      const precioEnDolar = montoTotal / dolar;
      return Math.ceil(precioEnDolar).toLocaleString("de");
    },
    precioEnDolaresPorPersona() {
      const {
        monedas: { dolar },
        vuelosElegidos: {
          info: { pasajeros },
          ida: {
            data: { precio, precioEnDolares },
          },
        },
      } = this;
      const { montoTotal } = this;
      if (precioEnDolares) return precioEnDolares;
      const precioEnDolarPorPersona = montoTotal / dolar / pasajeros;
      return Math.ceil(precioEnDolarPorPersona).toLocaleString("de");
    },
    precioEnCLPBasadoEnDolarDeHoy() {
      const {
        monedas: { dolar },
        vuelosElegidos: {
          ida: {
            data: { precio, precioEnDolares },
          },
          info: { pasajeros },
        },
      } = this;
      if (precioEnDolares) return +precioEnDolares * +dolar * +pasajeros;
      return Math.ceil(precio * pasajeros);
    },
    precioEnCLPBasadoEnDolarDeHoyPorPersona() {
      const {
        precioEnCLPBasadoEnDolarDeHoy,
        vuelosElegidos: {
          info: { pasajeros },
        },
      } = this;

      return precioEnCLPBasadoEnDolarDeHoy / pasajeros;
    },
    precioTotal() {
      return this.montoTotal;
    },
    precioEnMiles: () => (numero) => {
      if (numero === undefined) {
        numero = 0;
      }

      return new Intl.NumberFormat("de-DE").format(Math.round(numero));
    },
    tipoViajeYVuelo() {
      const tipoViaje = this.vuelosElegidos.info.tipoDeViaje.slice(0, -1);
      const tipoVuelo = this.vuelosElegidos.info.tipoDeVuelo;

      return tipoViaje === "Experiencia"
        ? "Experiencia"
        : `${tipoViaje} ${tipoVuelo}`;
    },
    preciosPorPersonaConFormato() {
      const precioIda = this.precioEnMiles(this.vuelosElegidos.ida.data.precio);
      const precioVuelta = this.vuelosElegidos.vuelta
        ? this.precioEnMiles(this.vuelosElegidos.vuelta.data.precio)
        : false;

      return {
        ida: precioIda,
        vuelta: precioVuelta,
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.cardResumen {
  &__pie {
    color: #707070;
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }

    &__precio {
      h2 {
        font-size: 25px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
</style>
