<template>
  <div class="TicketInfoVuelo">
    <div class="TicketInfoVuelo__infoGeneral">
      <h2>
        {{ vuelosElegidos.ida.data.origen }} -
        {{ vuelosElegidos.ida.data.destino }}
      </h2>
      <h4>
        {{
          vuelosElegidos.info.tipoDeViaje === "Vuelos"
            ? vuelosElegidos.info.tipoDeVuelo
            : lenguages[idioma].cardReservarVuelo.experiencia
        }}
        {{
          vuelosElegidos.ida.data.promocionXPlus1
            ? `${vuelosElegidos.ida.data.capacidad} ${lenguages[idioma].cardReservarVuelo.pasajeros}`
            : vuelosElegidos.info.pasajeros > 1
            ? `${vuelosElegidos.info.pasajeros} ${lenguages[idioma].cardReservarVuelo.pasajeros}`
            : `${vuelosElegidos.info.pasajeros} ${lenguages[idioma].cardReservarVuelo.pasajero}`
        }}
      </h4>
    </div>
    <div v-if="mostrarDetalles" class="TicketInfoVuelo__infoEspecifica">
      <v-row no-gutters class="pb-4">
        <v-col cols="6">
          <h3>
            {{
              vuelosElegidos.info.tipoDeVuelo === "Ida y vuelta"
                ? "IDA"
                : lenguages[idioma].cardReservarVuelo.fechaDeVuelo
            }}
          </h3>
          <h4>
            {{
              vuelosElegidos.ida.data.fecha ?
              vuelosElegidos.ida.data.fecha 
                .split("-")
                .reverse()
                .join("-")
                : 'Falta seleccionar...'
            }}
          </h4>
        </v-col>
        <v-col cols="6" v-show="vuelosElegidos.ida.data.horaDespegue">
          <h3>{{ lenguages[idioma].cardReservarVuelo.horaDeAbordaje }}</h3>
          <h4>{{ vuelosElegidos.ida.data.horaDespegue }}</h4>
        </v-col>
        <v-col cols="6">
          <h3>{{ lenguages[idioma].cardReservarVuelo.aeronave }}</h3>
          <h4 class="pb-3">
            {{
              vuelosElegidos.ida.data.aeronave === "helicoptero"
                ? lenguages[idioma].cardReservarVuelo.helicoptero 
                : lenguages[idioma].cardReservarVuelo.avion 
            }}
          </h4>
        </v-col>
        <!-- <v-col cols="6">
          <h3>Operador</h3>
          <h4>{{ vuelosElegidos.ida.data.operador }}</h4>
        </v-col> -->
        <v-col cols="12">
          <h3>{{ lenguages[idioma].cardReservarVuelo.codigoDeVuelo }}</h3>
          <h4>{{ vuelosElegidos.ida.id }}</h4>
        </v-col>
      </v-row>
      <hr v-if="vuelosElegidos.vuelta" />
      <v-row no-gutters class="pt-3" v-if="vuelosElegidos.vuelta">
        <v-col cols="6">
          <h3>VUELTA</h3>
          <h4>
            {{
              vuelosElegidos.vuelta.data.fecha
                .split("-")
                .reverse()
                .join("-")
            }}
          </h4>
        </v-col>
        <v-col cols="6">
          <h3>Hora de abordaje</h3>
          <h4>{{ vuelosElegidos.vuelta.data.horaDespegue }}</h4>
        </v-col>
        <v-col cols="6">
          <h3>Aeronave</h3>
          <h4>
            {{
              vuelosElegidos.vuelta.data.aeronave === "helicoptero"
                ? "Helicóptero"
                : "Avión"
            }}
          </h4>
        </v-col>
        <!-- <v-col cols="6">
          <h3>Operador</h3>
          <h4>{{ vuelosElegidos.vuelta.data.operador }}</h4>
        </v-col> -->
        <v-col cols="12">
          <h3>Código de vuelo</h3>
          <h4>{{ vuelosElegidos.vuelta.id }}</h4>
        </v-col>
      </v-row>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TicketInfoVuelo",
  props: {
    mostrarDetalles: {
      default: true,
    },
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState("Ventas", ["vuelosElegidos"]),
  },
};
</script>

<style lang="scss" scoped>
.TicketInfoVuelo {
  // width: 310px;
  &__infoGeneral,
  &__infoEspecifica {
    background: $main-white;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    padding: 30px;
  }
  &__infoGeneral {
    border-bottom: 1px dashed $main-black;
  }
}
</style>
