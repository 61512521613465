<template>
  <div class="aeronavesEscogidas">
    <div
      class="d-flex align-center"
      :class="{ 'justify-space-between': iconos.vuelta }"
    >
      <div class="aeronavesEscogidas__iconoIda">
        <img :src="iconos.ida" width="100%" />
      </div>
      <div class="aeronavesEscogidas__info ml-3">
        <h3>{{ titulo.ida }}</h3>
        <h3 v-if="titulo.vuelta" class="text-right">{{ titulo.vuelta }}</h3>
        <p v-if="subtituloVentas">{{ subtituloVentas }}</p>
      </div>
      <div v-if="iconos.vuelta" class="aeronavesEscogidas__iconoVuelta ml-3">
        <img :src="iconos.vuelta" width="100%" />
      </div>
    </div>
    <v-divider class="my-6"></v-divider>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HeaderAeronave",
  data() {
    return {};
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState("Ventas", ["vuelosElegidos"]),
    ...mapState(["colors", "icons"]),
    iconos() {
      const iconoAvion = this.icons.purple.plane;
      const iconoHelicoptero = this.icons.purple.helicopter.straight;
      const aeronaveIda = this.vuelosElegidos.ida.data.aeronave;
      const aeronaveVuelta = this.vuelosElegidos.vuelta
        ? this.vuelosElegidos.vuelta.data.aeronave
        : null;

      let iconosAeronaves = {
        ida: null,
        vuelta: null,
      };

      if (
        (this.vuelosElegidos.info.tipoDeViaje === "Vuelos" &&
          this.vuelosElegidos.tipoDeVuelo === "Solo ida") ||
        this.vuelosElegidos.info.tipoDeViaje === "Experiencias"
      ) {
        iconosAeronaves.ida =
          aeronaveIda === "avion" ? iconoAvion : iconoHelicoptero;
      } else {
        iconosAeronaves.ida =
          aeronaveIda === "avion" ? iconoAvion : iconoHelicoptero;

        iconosAeronaves.vuelta = !aeronaveVuelta
          ? null
          : aeronaveVuelta === "avion"
          ? iconoAvion
          : iconoHelicoptero;

        iconosAeronaves.ida === iconosAeronaves.vuelta
          ? (iconosAeronaves.vuelta = null)
          : false;
      }

      return iconosAeronaves;
    },
    titulo() {
      const aeronaveIda = !this.vuelosElegidos.ida
        ? null
        : this.vuelosElegidos.ida.data.aeronave === "avion"
        ? "avión"
        : "helicóptero";

      const aeronaveVuelta = !this.vuelosElegidos.vuelta
        ? null
        : this.vuelosElegidos.vuelta.data.aeronave &&
          this.vuelosElegidos.vuelta.data.aeronave === "avion"
        ? "avión"
        : "helicóptero";

      let titulo = { ida: null, vuelta: null };

      if (
        (aeronaveIda === aeronaveVuelta && aeronaveIda && aeronaveVuelta) ||
        !aeronaveVuelta
      ) {
        titulo.ida = `${aeronaveIda}`;
      } else {
        titulo.ida = `Ida en ${aeronaveIda},`;
        titulo.vuelta = `vuelta en ${aeronaveVuelta}`;
      }

      return titulo;
    },
    subtituloVentas() {
      const ventasIda = this.vuelosElegidos.ida.data.ventas;
      let subtitulo;

      if (this.vuelosElegidos.info.tipoDeVuelo === "Solo ida") {
        subtitulo =
          ventasIda > 1
            ? `${ventasIda} ${
                this.lenguages[this.idioma].cardReservarVuelo.personasCompraron
              }`
            : ventasIda === 1
            ? `${ventasIda} ${
                this.lenguages[this.idioma].cardReservarVuelo.personasCompraron
              }`
            : this.idioma == "es"
            ? "Sé el primero en comprar esta experiencia"
            : "Be the first to buy this experience";
      }
      return subtitulo;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.aeronavesEscogidas {
  width: 100%;
  &__iconoIda,
  &__iconoVuelta {
    @include flex;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }
  &__info {
    width: 67%;
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 12px;
    }
  }
  &__iconoVuelta {
    transform: scaleX(-1);
  }
}
</style>
